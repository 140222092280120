import { Flex } from '@chakra-ui/react'
import { NextSeo } from 'next-seo'
import dynamic from "next/dynamic";

const CategoryLayout = dynamic(() => import('@/component/category-row/CategoryLayout'));
const Header = dynamic(() => import('@/component/header/Header'));
const Products = dynamic(() => import('@/component/products-listing/Products'));
const MartlineInfo = dynamic(() => import('@/component/info/MartlineInfo'));

export default function Home() {

  return (
    <Flex
      flexDirection={'column'}>
      <NextSeo
        title={'Martlines | Home'}
        description={'All Nigerian markets at your finger tips with physical verified stores. Easy and dependable offline shopping'}
      />
      <Header />
      <CategoryLayout />
      <Products />
      <MartlineInfo />
    </Flex>
  )
}